import { getItem } from '@lib';
import { DEFAULT_LANGUAGE_CODE } from 'config/constants';
import { LANGUAGE } from 'config/storage';
import en from 'locales/en';
import es from 'locales/es';
import pt from 'locales/pt';
import { LangObj, LangObjDictionary } from 'types/language';

const defaultLangObj = en;
const languages: LangObjDictionary = { en, es, pt };

export type TranslationsType = typeof defaultLangObj;

const useLocale = (): LangObj => {
  const locale = getItem(LANGUAGE) ?? DEFAULT_LANGUAGE_CODE;

  if (process.env.NODE_ENV === 'production') return defaultLangObj;
  // TODO load language dynamically from the server
  if (locale in languages) return languages[locale];
  return defaultLangObj;
};
export default useLocale;
